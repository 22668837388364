import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('scopeClients', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    newContact(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`newContact`, leadData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    newScope(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`scope`, leadData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchScopes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchScopes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getLeadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`lead/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`lead`, leadData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`lead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    junkLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`junkLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    lostLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`lostLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    winLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`winLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    interactionLog(ctx, interactionLog) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`interactionLog`, interactionLog)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAssignLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`assignUpdate/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('leadUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadLead', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
